import React from 'react';
import PayrollReport from './PayrollReport';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
};

export default () => (
  <div style={styles.flex}>
    <div style={styles.leftCol}>
      <div style={styles.singleCol}>{<PayrollReport />}</div>
    </div>
  </div>
);
