import moment from 'moment';

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { ColorField, ColorInput } from 'react-admin-color-input';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  'no-text': {
    display: 'none',
  },
});

// Create our number formatter.
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default function DenseTable({ columns, rows }) {
  const classes = useStyles();

  const formatData = (type, value) => {
    if (!value) return;

    switch (type) {
      case 'color':
        return (
          <ColorField
            label=""
            source="color"
            record={{ color: value }}
            className={classes['no-text']}
          />
        );

      case 'currency':
        return formatter.format(value);
      case 'percent':
        return `${value}%`;
      case 'date':
        return moment.utc(value).format('MM/DD/YYYY');
      default:
        return value;
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell align="right" key={column.key}>
                <strong>{column.name}</strong>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id || 'total'}>
              {columns.map((column) => (
                <TableCell
                  key={`${row.id}/${column.key}`}
                  align="right"
                  style={{ textTransform: 'capitalize' }}
                >
                  {formatData(column.type, row[column.key])}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
