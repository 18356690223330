import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Create,
  Datagrid,
  DateInput,
  Edit,
  EditButton,
  Filter,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { ColorField, ColorInput } from 'react-admin-color-input';

import TimestampField from './TimestampField';
import useUser from './useUser';

const useStyles = makeStyles({
  'no-text': {
    display: 'none',
  },
});

const LeadTitle = ({ lead }) => {
  return <span>Lead {lead ? `"${lead.lead_id}"` : ''}</span>;
};

const LeadFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Sales Status"
      source="status"
      alwaysOn
      choices={[
        { id: 'scheduled', name: 'Scheduled' },
        { id: 'quoted', name: 'Quoted' },
        { id: 'sold', name: 'Sold' },
        { id: 'canceled', name: 'Canceled' },
        { id: 'hold', name: 'Hold' },
      ]}
    />
    <ReferenceInput
      label="Owner"
      source="owner_id"
      reference="users"
      allowEmpty
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      label="Installation Status"
      source="installStatus"
      allowEmpty
      choices={[
        { id: 'measured', name: 'Measured' },
        { id: 'ordered', name: 'Ordered' },
        { id: 'installed', name: 'Installed' },
        { id: 'remake', name: 'Remake' },
        { id: 'completed', name: 'Completed' },
      ]}
    />
    <TextInput label="Lead Name" source="name" alwaysOn />
    <TextInput label="Lead ID" source="lead_id" />
    <TextInput label="Email" source="email" />
    <TextInput label="Phone" source="phone" />
    <TextInput label="Address" source="address" />
    <TextInput label="City" source="city" />
    <TextInput label="Zip" source="zip" />
    <TextInput label="Notes" source="notes" />
  </Filter>
);

export const LeadList = ({ permissions, ...props }) => {
  const classes = useStyles();
  const user = useUser();

  // https://stackoverflow.com/questions/52487302/use-list-on-react-admin-dashboard
  return (
    <List
      {...props}
      filters={<LeadFilter />}
      filter={permissions && permissions.admin ? {} : { owner_id: user.uid }}
      sort={{ field: 'create_date', order: 'DESC' }}
    >
      <Datagrid>
        <EditButton />
        {/* <ColorField label="" source="color" className={classes['no-text']} /> */}
        <TextField
          label="Sales Status"
          source="status"
          style={{ textTransform: 'capitalize' }}
        />
        <TextField
          label="Installation Status"
          source="installStatus"
          style={{ textTransform: 'capitalize' }}
        />
        <ReferenceField
          label="Owner"
          source="owner_id"
          reference="users"
          link={permissions && permissions.admin}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="source" />
        <TextField source="lead_id" />
        <TimestampField label="Aquisition Date" source="create_date" />
        <TimestampField label="Signed Off Date" source="signed_off_date" />
        <TimestampField label="Ring Up Date" source="ring_up_date" />
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="phone" />
        <TextField source="address" />
        <TextField source="city" />
        <TextField source="zip" />
        <TextField source="notes" />
      </Datagrid>
    </List>
  );
};

const LeadForm = ({ permissions, ...props }) => (
  <SimpleForm {...props}>
    <Typography variant="h6" gutterBottom>
      Assignment
    </Typography>
    {permissions && permissions.admin ? (
      <ReferenceInput label="Owner" source="owner_id" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput>
    ) : (
      <ReferenceField
        label="Owner"
        source="owner_id"
        reference="users"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
    )}
    <Typography variant="h6" gutterBottom>
      Lead
    </Typography>
    <TextInput source="source" />
    <DateInput label="Aquistion Date" source="create_date" />
    <TextInput label="Lead ID" source="lead_id" />
    <TextInput label="Lead Name" source="name" />
    <TextInput source="email" />
    <TextInput source="phone" />
    <TextInput source="address" />
    <TextInput source="city" />
    <TextInput source="zip" />
    <TextInput multiline source="notes" />
    <Typography variant="h6" gutterBottom>
      Opportunity
    </Typography>
    <DateInput source="consultation_date" />

    <SelectInput
      source="call_record"
      choices={[
        { id: 'first', name: '1st Call' },
        { id: 'second', name: '2nd Call' },
        { id: 'thrid', name: '3rd Call' },
        { id: 'fouth', name: '4+ Call' },
      ]}
    />
    {/* <ColorInput source="color" picker="Github" /> */}
    <SelectInput
      label="Sales Status"
      source="status"
      choices={[
        { id: 'scheduled', name: 'Scheduled' },
        { id: 'quoted', name: 'Quoted' },
        { id: 'sold', name: 'Sold' },
        { id: 'canceled', name: 'Canceled' },
        { id: 'hold', name: 'Hold' },
      ]}
    />
    <NumberInput source="quote_amount" />
    <DateInput source="signed_date" />
    {permissions && permissions.admin && (
      <div>
        <Typography variant="h6" gutterBottom>
          Office Use
        </Typography>
        <SelectInput
          label="Installation Status"
          source="installStatus"
          allowEmpty
          choices={[
            { id: 'measured', name: 'Measured' },
            { id: 'ordered', name: 'Ordered' },
            { id: 'installed', name: 'Installed' },
            { id: 'remake', name: 'Remake' },
            { id: 'completed', name: 'Completed' },
          ]}
        />
        <DateInput source="ring_up_date" />
        <DateInput source="signed_off_date" />
        <DateInput source="completion_date" />
        <NumberInput source="labor" />
        <NumberInput source="remake" />
        <NumberInput source="initial_commission" />
        <DateInput source="initial_commission_paid_date" />
        <NumberInput source="final_commission" />
        <DateInput source="final_commission_paid_date" />
      </div>
    )}
  </SimpleForm>
);

export const LeadCreate = (props) => (
  <Create {...props}>
    <LeadForm {...props} />
  </Create>
);

const LeadEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton
      label="Save"
      redirect={false}
      submitOnEnter={false}
      variant="text"
    />
    <SaveButton label="Done" redirect="list" submitOnEnter={true} />
  </Toolbar>
);

export const LeadEdit = (props) => (
  <Edit title={<LeadTitle />} {...props}>
    <LeadForm {...props} toolbar={<LeadEditToolbar />} />
  </Edit>
);
