import React from 'react';
import PropTypes from 'prop-types';
import formatTimestamp from './formatTimestamp';

const TimestampField = ({ source, record = {} }) =>
    record && record[source] ? <span>{formatTimestamp(record[source])}</span> : '';

TimestampField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default TimestampField;
