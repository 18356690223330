import React from 'react';
import { Admin, Resource } from 'react-admin';
import Dashboard from './Dashboard';
import { LeadList, LeadCreate, LeadEdit } from './leads';
import { UserList } from './users';
import LeadIcon from '@material-ui/icons/Book';
import UserIcon from '@material-ui/icons/Group';
import { dataProvider, authProvider } from './firebase';

const App = () => (
  <Admin
    dashboard={Dashboard}
    dataProvider={dataProvider}
    authProvider={authProvider}
  >
    {(permissions) => [
      permissions && permissions.admin ? (
        <Resource
          name="leads"
          icon={LeadIcon}
          list={LeadList}
          edit={LeadEdit}
          create={LeadCreate}
        />
      ) : (
        <Resource
          name="leads"
          icon={LeadIcon}
          list={LeadList}
          edit={LeadEdit}
          exporter={false}
        />
      ),
      permissions && permissions.admin ? (
        <Resource name="users" icon={UserIcon} list={UserList} />
      ) : (
        <Resource name="users" />
      ),
    ]}
  </Admin>
);

export default App;
