import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from 'react-admin-firebase';
import firebase from 'firebase';

import firebaseConfig from './firebaseConfig';

const firebaseApp = firebase.initializeApp(firebaseConfig);

const options = {
  logging: true,
  // rootRef: 'rootrefcollection/QQG2McwjR2Bohi9OwQzP',
  app: firebaseApp,
  // watch: ['posts'];
  // dontwatch: ['comments'];
  persistence: 'session',
  // disableMeta: true
  // dontAddIdFieldToDoc: true,
  softDelete: true,
  associateUsersById: true,
};

if (window.location.hostname === 'localhost') {
  console.log(
    'testing locally -- hitting local functions and firestore emulators'
  );
  firebase.functions().useFunctionsEmulator('http://localhost:5001');
  firebase.firestore().settings({
    host: 'localhost:8080',
    ssl: false,
  });
}

const dataProvider = FirebaseDataProvider(firebaseConfig, options);
const authProvider = FirebaseAuthProvider(firebaseConfig, options);

export default firebase;
export { dataProvider, authProvider };
