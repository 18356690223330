import React, { useState, useEffect, useCallback } from 'react';
import { useVersion, useDataProvider, usePermissions } from 'react-admin';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  Select: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

function getStyles(id, ownerIdList, theme) {
  return {
    fontWeight:
      Array.isArray(ownerIdList) && ownerIdList.indexOf(id) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const LeadFilter = (props) => {
  const [users, setUsers] = useState([]);
  const theme = useTheme();
  const classes = useStyles();
  const version = useVersion();
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();

  const fetchUsers = useCallback(async () => {
    const resp = await dataProvider.getList('users', {
      filter: {},
      sort: { field: 'name', order: 'ASC' },
      pagination: { page: 1, perPage: 50 }, // lame but r-a-f requires this param
    });
    const users = resp ? resp.data : [];

    console.log('all users', users);
    setUsers(users);
  }, [dataProvider]);

  useEffect(() => {
    permissions && permissions.admin && fetchUsers();
  }, [fetchUsers, permissions, version]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {permissions && permissions.admin && (
        <FormControl>
          <InputLabel shrink id="owners-select-label">
            Owners
          </InputLabel>

          <Select
            labelId="owners-select-label"
            id="owner_ids"
            // className={classes.selectField}
            multiple
            value={props.ownerIdList}
            onChange={props.onOwnerListChange}
            input={<Input />}
            MenuProps={MenuProps}
          >
            {Array.isArray(users) &&
              users.map((user) => (
                <MenuItem
                  key={user.id}
                  value={user.id}
                  style={getStyles(user.id, props.ownerIdList, theme)}
                >
                  {user.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      <TextField
        id="start_date"
        label="Start"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        defaultValue={props.startDate}
        className={classes.textField}
        onChange={props.onStartChange}
      />
      <TextField
        id="end_date"
        label="End"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        defaultValue={props.endDate}
        className={classes.textField}
        onChange={props.onEndChange}
      />
    </>
  );
};

export default LeadFilter;
