import { useCallback, useEffect, useState } from 'react';
import { useAuthProvider } from 'react-admin';

export default () => {
  const authProvider = useAuthProvider();
  const [user, setUser] = useState({});
  const fetchUser = useCallback(async () => {
    const user = await authProvider.checkAuth();
    setUser(user);
  }, [authProvider]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return user;
};
