export default {
    apiKey: 'AIzaSyArW9ffwInEGvTLdnFFJ1lxCp2RR88E-bs',
    authDomain: 'eliteprojects-6d1e5.firebaseapp.com',
    databaseURL: 'https://eliteprojects-6d1e5.firebaseio.com',
    projectId: 'eliteprojects-6d1e5',
    storageBucket: 'eliteprojects-6d1e5.appspot.com',
    messagingSenderId: '34192219499',
    appId: '1:34192219499:web:ca75caad8b327c5af0802e',
    measurementId: 'G-DYM8T91ZL8',
};
