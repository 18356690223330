import React, { useState, useEffect, useCallback } from 'react';
import {
  useVersion,
  useDataProvider,
  useAuthProvider,
  usePermissions,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import LeadFilter from './LeadFilter';
import DenseTable from './DenseTable';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    flex: 1,
  },
  cost: {
    marginRight: '1em',
    color: theme.palette.text.primary,
  },
}));

const PayrollReport = ({
  ownerIdList: initOwnerIdList,
  startDate: initStartDate,
  endDate: initEndDate,
}) => {
  const [state, setState] = useState({});
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);
  const [ownerIdList, setOwnerIdList] = useState(initOwnerIdList || []);
  const version = useVersion();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const authProvider = useAuthProvider();
  const { permissions } = usePermissions();

  const fetchLeads = useCallback(
    async (ownerIdList, startDate, endDate) => {
      const user = await authProvider.checkAuth();

      const resp = await dataProvider.getList('leads', {
        filter: permissions && permissions.admin ? {} : { owner_id: user.uid },
        sort: { field: 'create_date', order: 'DESC' },
        pagination: { page: 1, perPage: 3000 },
      });

      if (!resp) {
        return;
      }

      const { data: leads } = resp;

      let newSalesLeads = leads
        .filter((lead) => lead.status === 'sold')
        .filter((lead) => !!lead.ring_up_date);

      let completedSalesLeads = leads
        .filter((lead) => lead.status === 'sold')
        .filter((lead) => !!lead.signed_off_date);

      console.log('selected owners', ownerIdList);

      if (Array.isArray(ownerIdList) && ownerIdList.length) {
        newSalesLeads = newSalesLeads.filter(
          (lead) => ownerIdList.indexOf(lead.owner_id) !== -1
        );

        completedSalesLeads = completedSalesLeads.filter(
          (lead) => ownerIdList.indexOf(lead.owner_id) !== -1
        );
      }

      if (startDate) {
        const startTime = new Date(startDate).getTime();

        newSalesLeads = newSalesLeads.filter(
          (lead) => startTime <= new Date(lead.ring_up_date).getTime()
        );

        completedSalesLeads = completedSalesLeads.filter(
          (lead) => startTime <= new Date(lead.signed_off_date).getTime()
        );
      }

      if (endDate) {
        let endTime = new Date(endDate);
        endTime = endTime.setDate(endTime.getDate() + 1); // add 1 day

        newSalesLeads = newSalesLeads.filter(
          (lead) => endTime > new Date(lead.ring_up_date).getTime()
        );

        completedSalesLeads = completedSalesLeads.filter(
          (lead) => endTime > new Date(lead.signed_off_date).getTime()
        );
      }

      let newSalesGrandTotals = {
          quote_amount: 0,
          labor: 0,
          ring_up_pay: 0,
        },
        completedSalesGrandTotals = {
          quote_amount: 0,
          labor: 0,
          remake: 0,
          ring_up_pay: 0,
          signed_off_pay: 0,
          total: 0,
        };

      if (newSalesLeads && newSalesLeads.length > 0) {
        const newLeadOwnerIds = newSalesLeads
          .filter((lead) => lead.owner_id)
          .map((lead) => lead.owner_id);

        let newLeadOwners;

        if (newLeadOwnerIds) {
          const { data: users } = await dataProvider.getMany('users', {
            ids: newLeadOwnerIds,
          });

          newLeadOwners = users.reduce((prev, user) => {
            prev[user.id] = user; // eslint-disable-line no-param-reassign
            return prev;
          }, {});

          setState((state) => ({
            ...state,
            newLeadOwners,
          }));
        }

        newSalesLeads = newSalesLeads
          .map((lead) => ({
            ...{
              quote_amount: 0,
              labor: 0,
              remake: 0,
              initial_commission: 0,
              final_commission: 0,
            },
            ...lead,
          }))
          .map((lead) => ({
            ...lead,
            owner:
              newLeadOwners[lead.owner_id] && newLeadOwners[lead.owner_id].name,
            ring_up_pay:
              (lead.quote_amount - lead.labor) *
              (lead.initial_commission / 100),
          }));

        console.log('new sales leads', newSalesLeads);
        newSalesGrandTotals = newSalesLeads.reduce(
          (result, lead) => ({
            quote_amount: result.quote_amount + lead.quote_amount,
            labor: result.labor + lead.labor,
            ring_up_pay: result.ring_up_pay + lead.ring_up_pay,
          }),
          newSalesGrandTotals
        );

        newSalesLeads.push(newSalesGrandTotals);
      }

      if (completedSalesLeads && completedSalesLeads.length > 0) {
        const completedLeadOwnerIds = completedSalesLeads
          .filter((lead) => lead.owner_id)
          .map((lead) => lead.owner_id);

        let completedLeadOwners;

        if (completedLeadOwnerIds) {
          const { data: users } = await dataProvider.getMany('users', {
            ids: completedLeadOwnerIds,
          });

          completedLeadOwners = users.reduce((prev, user) => {
            prev[user.id] = user; // eslint-disable-line no-param-reassign
            return prev;
          }, {});

          setState((state) => ({
            ...state,
            completedLeadOwners,
          }));
        }

        completedSalesLeads = completedSalesLeads
          .map((lead) => ({
            ...{
              quote_amount: 0,
              labor: 0,
              remake: 0,
              initial_commission: 0,
              final_commission: 0,
            },
            ...lead,
          }))
          .map((lead) => {
            const adjAmount = lead.quote_amount - lead.labor;
            const ringUpPay = adjAmount * (lead.initial_commission / 100);
            const signedOffPay =
              adjAmount * (lead.final_commission / 100) - lead.remake;
            return {
              ...lead,
              owner:
                completedLeadOwners[lead.owner_id] &&
                completedLeadOwners[lead.owner_id].name,
              ring_up_pay: ringUpPay,
              signed_off_pay: signedOffPay,
              total: ringUpPay + signedOffPay,
            };
          });

        completedSalesGrandTotals = completedSalesLeads.reduce(
          (result, lead) => ({
            quote_amount: result.quote_amount + lead.quote_amount,
            labor: result.labor + lead.labor,
            remake: result.remake + lead.remake,
            ring_up_pay: result.ring_up_pay + lead.ring_up_pay,
            signed_off_pay: result.signed_off_pay + lead.signed_off_pay,
            total: result.total + lead.total,
          }),
          completedSalesGrandTotals
        );

        completedSalesLeads.push(completedSalesGrandTotals);
      }

      const periodTotal = {
        ringUpPay: newSalesGrandTotals.ring_up_pay,
        signedOffPay: completedSalesGrandTotals.signed_off_pay,
        periodPay:
          newSalesGrandTotals.ring_up_pay +
          completedSalesGrandTotals.signed_off_pay,
      };

      console.log('pt', periodTotal);

      setState((state) => ({
        ...state,
        periodTotal,
      }));

      setState((state) => ({
        ...state,
        newLeads: newSalesLeads,
      }));

      setState((state) => ({
        ...state,
        completedLeads: completedSalesLeads,
      }));
    },
    [dataProvider, authProvider, permissions]
  );

  useEffect(() => {
    fetchLeads(ownerIdList, startDate, endDate);
  }, [fetchLeads, permissions, ownerIdList, startDate, endDate, version]);

  const handleOwnerListChange = (e) => {
    setOwnerIdList(e.target.value);
  };

  const handleStartChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndChange = (e) => {
    setEndDate(e.target.value);
  };

  const {
    periodTotal,
    newLeads,
    newLeadOwners,
    completedLeads,
    completedLeadOwners,
  } = state;

  const hasNewLeadOwners = newLeadOwners && Object.keys(newLeadOwners).length;
  const hasNewSalesData = newLeads && newLeads.length && hasNewLeadOwners;
  const hasCompletedLeadOwners =
    completedLeadOwners && Object.keys(completedLeadOwners).length;
  const hasCompletedSalesData =
    completedLeads && completedLeads.length && hasCompletedLeadOwners;

  Array.isArray(completedLeads) && completedLeads.map((lead) => lead.id);
  const filterData = {
    ownerIdList,
    startDate,
    endDate,
  };

  const periodTotalColumns = [
    { key: 'ringUpPay', name: 'Ring Up Pay', type: 'currency' },
    { key: 'signedOffPay', name: 'Signed Off Pay', type: 'currency' },
    { key: 'periodPay', name: 'Period Pay', type: 'currency' },
  ];

  const newSalesColumns = [
    // { key: 'color', name: '', type: 'color' },
    { key: 'owner', name: 'Designer', type: 'string' },
    { key: 'name', name: 'Customer', type: 'string' },
    { key: 'signed_date', name: 'Signed', type: 'date' },
    { key: 'ring_up_date', name: 'Ring Up', type: 'date' },
    { key: 'quote_amount', name: 'Amount', type: 'currency' },
    { key: 'labor', name: 'Labor', type: 'currency' },
    { key: 'initial_commission', name: 'Ring Up Rate', type: 'percent' },
    { key: 'ring_up_pay', name: 'Ring Up Pay', type: 'currency' },
  ];

  const completedSalesColumns = [
    // { key: 'color', name: '', type: 'color' },
    { key: 'owner', name: 'Designer', type: 'string' },
    { key: 'name', name: 'Customer', type: 'string' },
    { key: 'signed_date', name: 'Signed', type: 'date' },
    { key: 'ring_up_date', name: 'Ring Up', type: 'date' },
    { key: 'signed_off_date', name: 'Signed Off', type: 'date' },
    { key: 'completion_date', name: 'Completion', type: 'date' },
    { key: 'quote_amount', name: 'Amount', type: 'currency' },
    { key: 'labor', name: 'Labor', type: 'currency' },
    { key: 'remake', name: 'Remake', type: 'currency' },
    // { key: 'initial_commission', name: 'Ring Up Rate', type: 'percent' },
    // { key: 'ring_up_pay', name: 'Ring Up Pay', type: 'currency' },
    { key: 'final_commission', name: 'Signed Off Rate', type: 'percent' },
    { key: 'signed_off_pay', name: 'Signed Off Pay', type: 'currency' },
    // { key: 'total', name: 'Pay', type: 'currency' },
  ];
  return (
    <Card className={classes.root}>
      <CardHeader title="Payroll" />
      <CardContent>
        <LeadFilter
          {...filterData}
          onOwnerListChange={handleOwnerListChange}
          onStartChange={handleStartChange}
          onEndChange={handleEndChange}
        />

        {periodTotal && periodTotal.periodPay ? (
          <div>
            <h3>Period Pay</h3>
            <DenseTable columns={periodTotalColumns} rows={[periodTotal]} />
          </div>
        ) : (
          ''
        )}

        <h3>New Sales</h3>
        {hasNewSalesData ? (
          <DenseTable columns={newSalesColumns} rows={newLeads} />
        ) : (
          'No sales found'
        )}

        <h3>Completed Sales</h3>

        {hasCompletedSalesData ? (
          <DenseTable columns={completedSalesColumns} rows={completedLeads} />
        ) : (
          'No sales found'
        )}
      </CardContent>
    </Card>
  );
};

export default PayrollReport;
